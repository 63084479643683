<template>
    <div class="mobile-code">
        <a-button type="primary" @click="sendCode" v-if="!timer">获取验证码</a-button>
        <a-button type="primary" disabled v-else>{{time}} s后重新获取</a-button>
    </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import tool from '@/util/tool'
import loginModel from '@/api/login'
export default {
    name:"com-send-mobile-code",
    props:{
        mobile:{
            type:String
        },
        codeType:{
            type:Number,
            default:1  //发送验证码
        },
        geetCheck:{     //极验验证
            type:Boolean,
            default:true
        }
    },
    setup(props,context) {
        const state = reactive({
            timer:null,
            time:60,
            verify_id:0,
            verify:null,
            captchaObj:null,
            is_check:true
        })

        if( props.geetCheck ){
            loginModel.getGeeTestVerify().then(result=>{
                if( result.is_check ){
                    let data = result.data.geetest_response
                    //请检测data的数据结构， 保证data.gt, data.challenge, data.success有值
                    initGeetest({
                        // 以下配置参数来自服务端 SDK
                        gt: data.gt,
                        challenge: data.challenge,
                        offline: !data.success,
                        new_captcha: true,
                        product: 'bind',
                    }, function (captchaObj) {
                        state.captchaObj = captchaObj
                        captchaObj.onReady(function(){
                            //验证码ready之后才能调用verify方法显示验证码
                        }).onSuccess(function(){
                            var obj = captchaObj.getValidate();
                            sendMobileCode( { geetest_userid:result.data.geetest_userid,...obj } )
                        }).onError(function(err){
                            console.log('err',err);
                        })
                    })
                }else{
                    state.is_check = false
                }
            })
        }

        function sendMobileCode(verify){
            loginModel.sendShortMsgCode(props.mobile,props.codeType,verify).then(res=>{
				state.verify = res
                context.emit("success",res)
				const TIME_COUNT = 60
				if( !state.timer ){
					state.time = TIME_COUNT
					state.timer = setInterval(()=>{
						if( state.time >0 && state.time <=TIME_COUNT){
							state.time--;
						}else{
							clearInterval(state.timer)
							state.timer = null
						}
					},1000)
					return
				}
			})
        }

        function sendCode(){
            if( !props.mobile ){
                tool.message("请先输入手机号","warning")
                return
            }

            if( state.is_check ){
				state.captchaObj.verify()
			}else{
				sendMobileCode( null )
			}
        }

        return{
            ...toRefs(state),
            sendCode
        }
    },
}
</script>
