<template>
	<div id="login">
		<div class="login-wrap" :style="loginbg" v-if="config">
			<div class="login-con">
				<img v-if="config && config.login_front_back_url" class="login-con-left" :src="config.login_front_back_url" alt="">
				<img v-else class="login-con-left" src="@/assets/img/login-left.png" alt="">

                <div class="change-login-method">
                    <p class="clm-tag">{{login_type == 1 ?'微信':'密码'}}登陆</p>
                    <img v-if="login_type == 1" @click="getLoginQrcode" class="scan-login" src="@/assets/img/scan-login.png" alt="">
                    <img v-if="login_type == 2" @click="login_type = 1" class="scan-login" src="@/assets/img/account-login.png" alt="">
                </div>

				<!-- 账号密码登录 -->
                <div class="login-con-form" v-if="login_type == 1">
					<div class="title">登录智慧农场管理后台</div>
					<div class="title-eng">Log in to the background of smart farm management</div>
					<div class="form">
						<form @submit.prevent="loginNow($event)">
							<div class="form-input" :class="focus == 1 ?'form-input-active':''">
								<i class="ri-user-2-line ri-lg ri-lg"></i>
								<p class="form-holder" :class="(focus == 1 || mobile !='' ) ?'active':''">请输入登录账号</p>
								<input type="text" v-model="mobile" @blur="onBlur" @focus="getFocus(1)">
							</div>
							<div class="form-input" :class="focus == 2 ?'form-input-active':''">
								<i class="ri-key-line ri-lg ri-lg"></i>
								<p class="form-holder" :class="(focus == 2 || password !='') ?'active':''">请输入登录密码</p>
								<input type="password" v-model="password"  @blur="onBlur" @focus="getFocus(2)">
							</div>
								<button type="submit" class="form-btn">立 即 登 录</button>
							<div class="go-register">
								<router-link to="/store/1">
									<span @click="toStoreLogin">商户登录</span>
								</router-link>
								<router-link to="/register">
									<span @click="toRegister">没有账号?立即注册</span>
								</router-link>
							</div>
							<div class="lc-tips">
								建议使用
								<a href="https://wxapp.cqkundian.com/ChromeSetup.exe" target="_blank" title="立即下载">谷歌浏览器</a>
							</div>
						</form>
					</div>
				</div>

                <!-- 扫码登陆 -->
                <div class="login-con-qrcode" v-if="login_type == 2">
                    <div class="qrcode" id="wx-qrcode" v-if="wxLogin.appid">
                        <wxlogin
                            style="margin-left: 25px"
                            :appid="wxLogin.appid"
                            :scope="wxLogin.scope"
                            :redirect_uri="wxLogin.redirect_uri">
                        </wxlogin>
                    </div>
                    <p class="to-register">还没账号？
                        <router-link to="/register">
                            <a href="#">立即注册</a>
                        </router-link>
                    </p>
                </div>

			</div>
		</div>
		<div class="footer-version" v-if="config"><span v-html="config.footer"></span></div>

        <a-modal title="绑定手机号" :visible="show.bindPhone" :footer="null" width="500px" @cancel="show.bindPhone = false">
            <a-form :label-col="{span:4}" :wrapper-col="{span:12}">
                <a-form-item label="手机号">
                    <a-input style="width:200px" v-model:value="form.mobile"></a-input>
                </a-form-item>
                <a-form-item label="验证码">
                    <a-space>
                        <a-input style="width:200px" v-model:value="form.code"></a-input>
                        <com-send-mobile-code
                            :mobile="form.mobile"
                            :code-type="4"
                            @success="e=>form.verify = e">
                        </com-send-mobile-code>
                    </a-space>
                </a-form-item>
                <a-form-item :wrapper-col="{offset:4}">
                    <a-button type="primary" @click="sureBindPhone">立即绑定</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
	</div>
</template>

<script>
import wxlogin from 'vue-wxlogin';
import comSendMobileCode from '@/components/func/com-send-mobile-code.vue'
import { computed, reactive, toRefs } from 'vue';
import loginModel from '@/api/login.js'
import { useRoute } from 'vue-router';
import tool from '@/util/tool';

export default {
    components:{
        wxlogin,
        comSendMobileCode
    },
	setup(){
		const _d = reactive({
			focus:"0",		//1=输入账号 2输入密码
			mobile:"",
			password:"",
			config:null,
            login_type:1,   //登陆方式 1账号密码登陆 2二维码扫码登陆
            // wx_qrcode:"",
            timer:null,

            wxLogin:{
                //wx92ad443610c92f21
                appid:"",
                scope:"snsapi_login",
                redirect_uri:"",
            },
            show:{
                bindPhone:false
            },
            form:{
                mobile:"",
                code:"",
                verify:"",
                data:null,
            }
		})
		const options = useRoute().query
		initData();

        if( options.code ){
            if( options.login_type == 2 ){    //微信授权登陆
                checkScan(options.code )
            }else{
                //判断当前是否从云端登录
                loginModel.kdCloudLogin(options.code);
            }
        }

		//已存在token 直接登录成功
		if( localStorage.getItem('token') ){
			loginModel.loginSuccess();
		}
		//初始化获取配置信息
		function initData(){
			loginModel.getLoginSet().then(res=>{
				_d.config = res
			})

            // _d.wxLogin.redirect_uri = encodeURIComponent( window.location.href.split(':')[0] + '://'+window.location.host+'/login')
            _d.wxLogin.redirect_uri = encodeURIComponent( window.location.href.split(':')[0] + '://farm.test.farmkd.com/login')
		}
		const loginbg = computed(()=>{
			if( _d.config ) return `background-image: url(${_d.config.login_back_url});`;
			return ""
		})

        const onBlur = ()=>_d.focus = 0
        const getFocus = e =>_d.focus = e

		//立即登录
        const loginNow = ()=>loginModel.accountLogin(_d.mobile,_d.password)

        const getLoginQrcode = ()=>{
            _d.login_type = 2
            loginModel.getWxLoginConfig(res=>{
                _d.wxLogin.appid = res.appid
                let domain = window.location.href.split(':')[0]+'://'
                if( process.env.NODE_ENV  == 'development'){
                    _d.wxLogin.redirect_uri = encodeURIComponent( domain + 'farm.test.farmkd.com/login?login_type=2')
                }else{
                    console.log('生产环境');
                    _d.wxLogin.redirect_uri = encodeURIComponent( domain + window.location.host+'/login?login_type=2')
                }

            })
        }

        function checkScan(code){
            loginModel.checkWxLogin('login',code,res=>{
                console.log('res',res);
                if( !res.status ){
                    tool.message(res.msg)
                    return
                }
                //未绑定手机号
                if( res.bindMobile ){
                    _d.show.bindPhone = true
                    _d.form.data = res.data
                    return
                }
            })
        }

        function sureBindPhone(){
            loginModel.bindMobile(_d.form,'login')
        }

		return{
			...toRefs(_d),
			loginbg,
			onBlur,
			getFocus,
			loginNow,
            getLoginQrcode,
            sureBindPhone
		}
	},

};
</script>
<style lang="scss" scoped>
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}
.login-wrap {
	width: 100%;
	position: fixed;
	height: 100%;
	text-align: center;
}


.login-con{
	width: 1000px;
	height: 500px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	display: flex;
	border-radius: 12px;

	&-left{
		width: 500px;
		height: 500px;
	}

	&-form{
		width: 500px;
		height: 500px;
		background: #fff;
		border-radius: 0 12px 12px 0;
		text-align: center;
		padding-top: 32px;

		.title{
			width: 100%;
			text-align: center;
			font-size: 24px;
			color: #1890ff;
			font-weight: bold;
		}
		.title-eng{
			color: #1890ff;
			font-size: 12px;
		}

		.form{
			width: 300px;
			margin-left: 100px;
			margin-top: 48px;

			.form-input{
				display: flex;
				align-items: center;
				height: 48px;
				border-bottom: 2px solid;
				padding: 0 12px;
				margin-bottom: 36px;
				border-bottom-color: rgba($color: #4a9ef4, $alpha: .6);

				>i{
					color: #4a9ef4;
				}

				input{
					width: 250px;
					height: 40px;
					margin-left: 12px;
					border: none;
					outline: none;
					font-size: 18px;
					background: none;
					z-index: 9;

					position: absolute;
					margin-left: 32px;
				}

				.form-holder{
					margin-left: 12px;
					font-size: 18px;
					color: #999;
					margin-top: 14px;
					transition: linear .2s;
				}

				.active{
					font-size: 12px;
					margin-top: -48px;
				}
			}
			.form-input-active{
				border-bottom-color: #4a9ef4;
			}
			.form-btn{
				width: 100%;
				height: 48px;
				border-radius: 6px;
				margin-top: 24px;
				border: none;
				background: #1890ff;
				color: #fff;
				font-size: 18px;
				cursor: pointer;
				transition: linear .2s;
				outline: none;
			}
			.form-btn:hover{
				background: rgba($color: #1890ff, $alpha: .8);
			}
		}
	}

    &-qrcode{
        width: 500px;
		height: 500px;
		background: #fff;
		border-radius: 0 12px 12px 0;
		text-align: center;
		padding-top: 32px;

        .qrcode{
            position: relative;
            margin-top: 24px;
            &-img{
                width: 100%;
                height: 100%;

            }
        }
    }

	.lc-tips {
		font-size: 12px;
		color: #999;
		margin-top: 40px;

		a {
			text-decoration: none;
			color: #29adeb;
		}
	}
}
.footer-version {
	width: 100%;
	height: 30px;
	line-height: 30px;
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	bottom: 0;
	text-align: center;
	color: #ffffff;
	font-size: 12px;

	p {
		line-height: 30px;
		margin: 0;
		padding: 0;
	}
}
.go-register {
	width: 100%;
	height: 50px;
	line-height: 50px;
	font-size: 12px;
	color: #4a4a4a;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	span:hover {
		color: #007aff;
	}
}
// 去掉input框自动填充背景色
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 32px #fff inset; /**通过边框阴影遮挡住背景*/
	-webkit-text-fill-color: #333; /*自动填充内容的文本颜色*/
}

.change-login-method{
    width: 200px;
    height: 60px;
    position: absolute;
    right: 12px;
    top: 12px;
    border-top-right-radius: 12px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;

    .scan-login{
        width: 42px;
        height: 42px;
    }
    .clm-tag{
        position: relative;
        margin: 0;
        margin-right: 8px;
        box-sizing: content-box;
        width: 87px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        font-size: 12px;
        background: #d9e5ff;
        border: 1px solid #bad0ff;
        color: #3778ff;
        margin-top: 6px;
    }
    .clm-tag:before{
        content: " ";
        border: 6px solid transparent;
        border-left-color: #bad0ff;
        z-index: 1;
        display: block;
        position: absolute;
        left: 100%;
        top: 50%;
        margin-top: -6px;
    }
    .clm-tag:after{
        content: " ";
        border: 6px solid transparent;
        border-left-color: #d6e4f7;
        z-index: 1;
        display: block;
        position: absolute;
        left: 100%;
        top: 50%;
        margin-top: -6px;
        transform: translateX(0.5px);
        margin-left: -2px;
    }
}

</style>
